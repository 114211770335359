// define it
@mixin selection {
  ::-moz-selection { @content; }
  ::selection { @content; }
}

// use it
@include selection {
  color: white;
  background: #C39BD3;
}

h1, h2, h3, h4, h5 {
  color: #000;
}

p {
  word-wrap: break-word;
}

.center {
  text-align: center;
}

.glassdoor-adjusted {
  max-width: 600px;
}

.site-header {
  border-top: none;
}

.container {
  width: auto;
  max-width: 880px;
  padding: 0 20px;
}
.container .text-muted {
  margin: 20px 0;
}

.text-center {
  text-align: center;
}

/*custom*/

.navbar-default{
  background-color: #fff;
  border-color: #eee;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  // height: 80px;
  // background-color: #2B2D34;
  color: #A3A5AC;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 48px;
  border-top: 1px solid rgba(192, 192, 191, 0.6);
}

.footer p {
  margin-bottom: 0px;
}

body {
  font-family: Avenir, 'avenir next', Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #454E55;
  padding-bottom: 50px;
}

// @media (min-width: 600px) {
  body {
    margin-bottom: 180px;
  }
// }

html {
  position: relative;
  min-height: 100%;
  width: 100%;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.post-content p {
  font-size: 16px;
}

blockquote p, blockquote li {
  font-size: 14px;
}

blockquote#sam-quote p {
  font-size: 15px;
}

@media (min-width: 768px) {

  .dataTables_scrollBody, .table-responsive {
    overflow-x: hidden;
  }

}

table.dataTable.no-footer, table.dataTable thead th, table.dataTable thead td {
  border-bottom: none;
}


.label {
  font-weight: normal;
  font-size: 14px;
}

.label-sf {
  background-color: #4398A3;
}

.label-pa {
  background-color: #94022D;
}

.label-YC, .label-S14, .label-S15 {
  background-color: #F26522;
}

.label-W15 {
  background-color: #888;
}

.label-edu {
  background-color: #0075C5;
}

.label-design {
  background-color: #55216F;
}

.label-Breakout {
  background-color: #D70B2F;
}

.label-Momentum {
  background-color: #5074BC;
}

.label-500plus {
  background-color: #492A70;
}

.label-150to500 {
  background-color: #6006D9;
}

.label-20to150 {
  background-color: #9C3AE5;
}

.label-0to20 {
  background-color: #DE0579;
}

.label-Network {
  background-color: #D14799;
}

.label-other {
  background-color: #581DF7;
}

.label-LowProfile {
  background-color: #444;
}

.label-Watch {
  background-color: #55216F;
}

//
// #114DEA //sick electric blue
// #55216F //nice dark purple
// #E05097 //nice light pink
// #47538E //nice grey blue
// #94022D nice red
// bright red D70B2F

.btn-notes {
  background-color: hsl(31, 100%, 42%) !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff9728", endColorstr="#d66e00");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#ff9728), to(#d66e00));
  background-image: -moz-linear-gradient(top, #ff9728, #d66e00);
  background-image: -ms-linear-gradient(top, #ff9728, #d66e00);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff9728), color-stop(100%, #d66e00));
  background-image: -webkit-linear-gradient(top, #ff9728, #d66e00);
  background-image: -o-linear-gradient(top, #ff9728, #d66e00);
  background-image: linear-gradient(#ff9728, #d66e00);
  border-color: #d66e00 #d66e00 hsl(31, 100%, 38%);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.26);
  color: #fff;
}

.company-table .logo-small img, .company-table img.logo-small.co-logo {
  max-height: 30px;
  max-width: 80px;
}

p, td, li {
  color: #7F7D7A;
}

.footer h4 {
  font-weight: 300;
}

span.logo {
  text-transform: uppercase;
  // font-variant: small-caps;
  font-weight: 700;
}

.btn-default {
  color: #7F7D7A;
}

.btn-default-blue {
  border-color: #337ab7;
  color: #337ab7;
}

.btn-default-black {
  border-color: #3A3A3A;
  color: #3A3A3A;
}

td.text-logo {
  text-align: right;
}

.twitter-follow-container {
  padding-top: 6px;
}

.btn-intouch {
  padding-top: 20px;
}

blockquote {
  padding: 10px 10px;
}

.work-here {
  margin-top: 10px;
}

.logo small {
  font-weight: 700;
  letter-spacing: -0.6px;
  font-size: 70%;
}

.footer .logo small {
  color: #000;
}

.form-group input {
  margin-bottom: 10px;
}

.mg-chart-title {
  text-align: left;
}

table.co-data th {
  text-align: right;
}

.investor-thumb {
  max-height: 50px;
  // width: 100px;
}

.board-thumb {
  max-height: 30px;
}

.funding-round {
  height: 630px;
}

.table thead tr th.th-none {
  border-bottom: none;
}

#iron-router-progress {
  background-color: #29d;
  height: 2px;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
}

.jqDropZone {
    background: lightgrey;
    width: 150px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
}
.jqDropZone.in {
    width: 600px;
    height: 200px;
    line-height: 200px;
    font-size: larger;
}
.jqDropZone.hover {
    background: lawngreen;
}
.jqDropZone.fade {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    opacity: 1;
}

.label-xs {
  font-size: 12px;
}

.company-table img.co-logo, .well-opp img.co-logo {
  max-width: 100px;
  max-height: 30px;
  padding-right: 16px;
  // margin-right: 10px;
  float: left;
}

@media screen and (max-width: 600px) {
  .page-header {
    margin-top: 10px;
    margin-bottom: 5px;
    border-bottom: none;
  }

  table {
    border: 0;
  }

  table thead {
    display: none;
  }

  table tr {
    display: block;
    border-bottom: 1px solid #777;
  }

  table tr:last-child {
    border-bottom: 0;
  }

  table td {
    display: block;
    text-align: right;
    font-size: 13px;
    min-height: 35px;
  }

  table td.people {
    min-height: 47px;
  }

  table td.empty {
    display: none;
  }

  table td:first-child {
    border-top: 0 !important;
  }

  table td:last-child {
    border-bottom: 0;
  }

  table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .table tbody tr td.logo-td {
    padding-bottom: 38px;
  }

  table td.interest, table th.interest {
    display: none;
  }

  h3 small {
    display: block;
    margin-top: 8px;
  }

  .company-table img.co-logo {
    max-height: 30px;
  }

  .company-table img.co-logo {
    float: right;
    margin: 0;
    padding: 0;
  }

  .company-table img.co-logo.img-thumbnail {
    padding: 2px;
  }

  table.people-data tr th {
    border-top: none;
  }

  .people-table img.co-logo {
    margin-left: 5px;
  }

  .twitter-btn {
    display: none;
  }

  .favs-switcher {
    display: none;
  }
}

.career-table img.co-logo {
  padding-right: 4px;
}

@media screen and (min-width: 601px) {
  .people-table img.co-logo {
    margin-right: 5px;
  }
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.footer-logo {
  max-height: 15px;
  margin-bottom: 5px;
}

.footer-logo-box {
  max-height: 30px;
  margin-bottom: 4px;
}

.nav-logo {
  max-height: 20px;
}

.list-switcher {
  padding-top: 10px;
  padding-bottom: 20px;
}

@media (max-width: 600px) {
  .list-switcher .btn-block {
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  #top-main-list-switcher {
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 1000px) {
  .list-switcher .btn-block {
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

td.tags, td.location, td.size, td.fr-yr, th.name-req, th.step {
  white-space: nowrap;
}

.label-tag {
  cursor: pointer;
  font-size: 13px;
}

.firm-thumb {
  max-height: 30px;
}

button.to-list {
  float: right;
}

.lowprofile-logo {
  max-height: 20px;
}

.tag-link:link,
.tag-link:visited,
.tag-link:hover,
.tag-link:focus,
.tag-link:active {
  text-decoration: none;
}

.post-header {
  padding-top: 20px;
  padding-bottom: 20px;
}

h1.post-title {
  margin-top: 5px;
  margin-bottom: 0px;
}

.notes p, .notes li {
  color: rgba(0,0,0,0.7);
  font-size: 15px;
}

.notes h5 {
  font-size: 17px;
  padding-top: 10px;
}

.notes h4 {
  padding-top: 20px;
}

.notes h2, .notes h3, .notes h4 {
  font-weight: 700;
}

.notes h2 {
  padding-bottom: 10px;
  padding-top: 20px;
}

.gh {
  padding-top: 10px;
}

.spinner {
  margin-top: -70px;
}

img.person-logo {
  max-height: 160px;
}

.notes h3 {
  padding-top: 20px;
}

.step-notes h3 {
  padding-top: 0px;
}

img.synapse {
  max-height: 400px;
}

.opportunities h4 {
  font-weight: bold;
}

div.opp-promo {
  min-height: 100%;
  min-height: 100vh;
}

@media screen and (min-width: 601px) {
  .opp-tagline {
    margin-top: -240px;
  }

  .opp-rec-tagline, .opp-recs {
    margin-top: -120px;
  }

  div.opp-promo, div.opp-recs {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  table.fav-co-table tr:last-child {
    border-bottom: 1px solid #ddd;
  }

  table.fav-co-table td {
    min-height: 39px;
  }
}

.fav-co-table img.co-logo, .fav-co-table img.co-logo.logo-small {
  max-height: 24px;
}

.opp-example {
  align-self: flex-start;
}

.well-opp {
  background-color: #fff;
}

ul.checkbox-list, ul.fav-co-list {
  list-style-type: none;
}

ul.checkbox-list li {
  color: rgba(60,20,22,0.4);
}

.checkbox-list .fa {
  color: #F8525E;
}

@media (min-width: 600px) {
  .product-col {
    word-wrap: break-word;
    max-width: 250px;
  }
}

@media (max-width: 600px) {
  .product-col {
    margin-left: 30px;
  }
}

img.logo-invert {
  -webkit-filter: invert(.8);
  filter: invert(.8);
}